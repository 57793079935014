<template>
  <div>
    <v-btn text small @click="onOpen"
      ><v-icon class="mr-1">mdi-delete</v-icon>Удалить</v-btn
    >

    <v-dialog v-model="dialog" max-width="600" @click:outside="onClose">
      <v-card>
        <v-card-title class="headline"
          >Удалить профиль: {{ profileName }}?</v-card-title
        >
        <v-card-actions>
          <v-btn color="error" @click="onDelete">Удалить</v-btn>
          <v-btn @click="onClose">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteProfile } from '@/lib/requestsAPI'

import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'
import { dialogMixin } from '@/mixins/dialogMixin'

import { PROFILES, ADMIN } from '@/store/const/path'

export default {
  props: ['profileName', 'profileId'],
  mixins: [dialogMixin, sendFormWithToastAndRedirect],
  methods: {
    async onDelete() {
      this.dialog = false
      this.sendFormWithToastAndRedirect({
        action: deleteProfile,
        data: this.profileId,
        successPath: `/${ADMIN}/${PROFILES}`,
        successMessage: `Профиль «${this.profileName}» удален`,
        errorMessage: `Не получается удалить профиль «${this.profileName}»`,
      })
    },
  },
}
</script>
