<template>
  <div>
    <div v-if="isLoading" class="d-flex align-center">
      <div class="mr-3">{{ loadingMessage }}</div>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="isError">{{ errorMessage }}</div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isLoading', 'isError', 'loadingMessage', 'errorMessage'],
}
</script>
