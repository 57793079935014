import callApi from '@/lib/callAPI'
import { GET, POST, DELETE, PATCH, PUT } from '@/store/const/apiMethods'
import {
  // common
  API_NOSOLOGIES,
  API_PROFILES,
  API_USERS,
  API_PROJECTS,
  API_PROJECTS_NOSOLOGIES,
  // login
  API_LOGIN,
  API_USER_RESET_PASSWORD,
  API_AUTH_SET_PASSWORD,
  API_AUTH_GET_INFO,
  API_REGISTRATION,
  // special work with project
  API_UNPIN_NOSOLOGIA,
  API_PIN_NOSOLOGIA_WITH_GVS,
  API_USERS_PROJECT_NOSOLOGIES,
  API_CRITERIA,
  API_MASS_CREATE,
  NEXT_STAGE,
  // criteria card
  API_USER_CRITERIA_STAGES,
  API_CRITERIA_STAGES,
} from '@/store/const/apiPaths'
import store from '@/store'

/* LOGIN */
export async function login(data) {
  let res = callApi(POST, API_LOGIN, data)
  return res
}

export async function register({ nmicToken, data }) {
  await callApi(POST, `${API_REGISTRATION}/${nmicToken}`, data)
}

/* NOSOLOGIES */
export async function addNosologia(data) {
  let res = await callApi(POST, API_NOSOLOGIES, data)
  store.dispatch('loadNosologies')
  return res.id
}

export async function editNosologia({ id, data }) {
  await callApi(PATCH, `${API_NOSOLOGIES}/${id}`, data)
  store.dispatch('loadNosologies')
}

export async function deleteNosologia(id) {
  await callApi(DELETE, `${API_NOSOLOGIES}/${id}`)
  store.dispatch('loadNosologies')
}

export async function markUsersAsChecked(id) {
  patchProjectNosologia(id, { is_users_check_needed: false })
}

export async function markCriteriaAsChecked(id) {
  patchProjectNosologia(id, { is_criteria_check_needed: false })
}

async function patchProjectNosologia(id, data) {
  await callApi(PATCH, `${API_PROJECTS_NOSOLOGIES}/${id}`, data)
  store.dispatch('loadNosologies')
}

/* PROFILES */
export async function addProfile(data) {
  let res = await callApi(POST, API_PROFILES, data)
  store.dispatch('loadProfiles')
  return res.id
}

export async function deleteProfile(id) {
  await callApi(DELETE, `${API_PROFILES}/${id}`)
  store.dispatch('loadProfiles')
}

export async function editProfile({ id, data }) {
  await callApi(PATCH, `${API_PROFILES}/${id}`, data)
  store.dispatch('loadProfiles')
}

/* USERS */
export async function addUser(data) {
  let res = await callApi(POST, API_USERS, data)
  store.dispatch('loadUsers')
  return res.id
}

export async function editUser({ id, data }) {
  await callApi(PATCH, `${API_USERS}/${id}`, data)
  store.dispatch('loadUsers')
}

export async function deleteUser(id) {
  await callApi(DELETE, `${API_USERS}/${id}`)
  store.dispatch('loadUsers')
}

/* AUTH */

export async function resetPassword(id) {
  let res = await callApi(POST, `${API_USERS}/${id}/${API_USER_RESET_PASSWORD}`)
  return res.token_reset_password
}

export async function setPassword(token, password) {
  await callApi(POST, `${API_AUTH_SET_PASSWORD}/${token}`, { password })
}

export async function getMaskedLogin(token) {
  const res = await callApi(GET, `${API_AUTH_GET_INFO}/${token}`)
  return res.login
}

/* PROJECTS CRUD */

export async function addProject(data) {
  const res = await callApi(POST, API_PROJECTS, data)
  store.dispatch('loadProjects')
  return res.id
}

export async function deleteProject(id) {
  await callApi(DELETE, `${API_PROJECTS}/${id}`)
  store.dispatch('loadProjects')
}

export async function editProject({ id, data }) {
  await callApi(PATCH, `${API_PROJECTS}/${id}`, data)
  store.dispatch('loadProjects')
}

/* PROJECTS OTHER ACTIONS */
export async function unpinNosologia(data) {
  await callApi(DELETE, API_UNPIN_NOSOLOGIA, data)
}

export async function pinNosologiaWithGvs(data) {
  await callApi(POST, API_PIN_NOSOLOGIA_WITH_GVS, data)
}

export async function cleanAllCriteries(data) {
  await callApi(DELETE, API_CRITERIA, data)
}

export async function addCriteries(data) {
  await callApi(POST, API_MASS_CREATE, data.criteries)
}

export async function toNextStage(id) {
  await callApi(POST, `${API_PROJECTS}/${id}/${NEXT_STAGE}`)
}

export async function toNextStageForce(id) {
  await callApi(POST, `${API_PROJECTS}/${id}/${NEXT_STAGE}?force`)
}

export async function pinUser(data) {
  await callApi(POST, API_USERS_PROJECT_NOSOLOGIES, data)
}

export async function unpinUser(id) {
  await callApi(DELETE, `${API_USERS_PROJECT_NOSOLOGIES}/${id}`)
}

export async function sendGVSAnswers(id) {
  await callApi(PATCH, `${API_USERS_PROJECT_NOSOLOGIES}/${id}`, {
    is_completed: true,
  })
}

export async function cancelGVSAnswers(id) {
  await callApi(PATCH, `${API_USERS_PROJECT_NOSOLOGIES}/${id}`, {
    is_completed: false,
  })
}

/* GVS WORK WITH CARD */
export async function updateCriteriaCard(data) {
  const res = await callApi(PUT, API_USER_CRITERIA_STAGES, data)
  return res.data
}

export async function changeIsAdminPass({ id, value }) {
  await callApi(PATCH, `${API_CRITERIA_STAGES}/${id}`, {
    is_approved: value,
  })
}
