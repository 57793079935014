<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader :text="profileName">
        <EditButton label="Редактировать" />
        <DeleteProfileWithDialog
          :profileName="profileName"
          :profileId="profileId"
        />
      </PageHeader>
      <div class="mb-12">
        <TitleAndDataLine label="Профиль" :value="profileName" />
      </div>
    </div>
    <div v-else>Нет профиля</div>
  </DisplayLoading>
</template>

<script>
import EditButton from '@/components/buttons/EditButton.vue'
import DeleteProfileWithDialog from '@/components/buttons/DeleteProfileWithDialog.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_PROFILES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю профиль',
    }
  },
  components: {
    EditButton,
    DeleteProfileWithDialog,
    PageHeader,
    DisplayLoading,
    TitleAndDataLine,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    profile() {
      return this.pageData
    },
    profileName() {
      return this.profile.name
    },
    loadUrl() {
      return `${API_PROFILES}/${this.profileId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для профиля: ${this.profileId}`
    },
  },
}
</script>
