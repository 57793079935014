import callApi from '@/lib/callAPI'
import { GET } from '@/store/const/apiMethods'

export const loadPageData = {
  data() {
    return {
      isError: false,
      pageData: null,
      isLoading: false,
    }
  },
  async created() {
    this.isError = false
    try {
      this.isLoading = true
      this.pageData = await callApi(GET, this.loadUrl)
    } catch (err) {
      this.$toast.error(this.errorMessage + '\n' + err)
      console.error(err)
      this.isError = true
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    async update() {
      this.isError = false
      try {
        this.pageData = await callApi(GET, this.loadUrl)
      } catch (err) {
        this.$toast.error(this.errorMessage + '\n' + err)
        console.error(err)
        this.isError = true
      }
    },
  },
}
